import React from "react";
import { Link } from "react-router-dom";


function ReferanceItem({name, image}) {
  return (
    <div className="scale-card" style={{transition: 'transform 0.2s ease-in-out', borderRadius: '6px',backgroundColor: '#fff',display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', width: '18%', height: '160px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
      <img className="scale-card-img" style={{width: '80px'}} src={`${process.env.PUBLIC_URL}/img/referances/${image}`} alt={name} />
      <h5 className="scale-card-text" style={{margin: '0', fontSize: '16px', letterSpacing: '0.4px', textTransform: 'uppercase', fontWeight: '500', textWrap: 'wrap', textAlign: 'center'}}>{name}</h5>
    </div>
  );
}

export default ReferanceItem;
