import React, { useState, useEffect } from 'react';
import ReferanceItem from '../ReferanceItem/ReferanceItem';
import referenceData from './ReferanceList.json';


function SectionReferance() {
    const [cardsPerRow, setCardsPerRow] = useState(5); // Başlangıçta 5 kart gösterilsin

    // Ekran genişliğine göre kart sayısını güncelle
    const updateCardsPerRow = () => {
        const width = window.innerWidth;
        if (width <= 480) {
            setCardsPerRow(1);
        } else if (width <= 768) {
            setCardsPerRow(2);
        } else if (width <= 992) {
            setCardsPerRow(3);
        } else if (width <= 1200) {
            setCardsPerRow(4);
        } else {
            setCardsPerRow(5); // 1200px ve üzeri için 5 kart
        }
    };

    // Ekran genişliği değiştikçe kart sayısını güncelle
    useEffect(() => {
        updateCardsPerRow();
        window.addEventListener('resize', updateCardsPerRow);
        return () => window.removeEventListener('resize', updateCardsPerRow);
    }, []);

    const renderSection = (title, key) => {
        const items = referenceData[key] || [];
        return (
            <div style={{width: '100%', paddingTop:'2rem', paddingBottom: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center', borderBottom: '3px rgba(0, 0, 0, 0.1) solid'}}>
                <div className='performanz-heading' style={{margin: '0'}}>
                    <h2>{title}</h2>
                </div>
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', gap: '16px'}}>
                    {Array.from({ length: Math.ceil(items.length / cardsPerRow) }, (_, rowIndex) => (
                        <div key={rowIndex} style={{display: 'flex', width: '100%', justifyContent: 'space-evenly'}}>
                            {items.slice(rowIndex * cardsPerRow, rowIndex * cardsPerRow + cardsPerRow).map(item => (
                                <ReferanceItem key={item.id} name={item.name} image={item.image} />
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div style={{width: '100%', userSelect: 'none', display: 'flex', alignItems: 'flex-start'}} className="performanz-bg-section">
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexDirection: 'column'}}>
                {renderSection("Kamu Kuruluşları", "kamuKuruluslari")}
                {renderSection("Spor Kulüpleri", "sporKulüpleri")}
                {renderSection("Üniversiteler", "universiteler")}
                {renderSection("Liseler", "liseler")}
                {renderSection("Özel", "ozel")}
            </div>
        </div>
    );
}

export default SectionReferance;
