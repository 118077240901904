import { Route, Router, Switch } from "react-router";
import "./App.css";
import MainSlider from "./components/MainSlider/MainSlider";
import SectionProduct from "./components/SectionProduct/SectionProduct";
import PublicLayout from "./layouts/PublicLayout";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
import Home from "./pages/Home";
import Products from "./pages/Products";

function App() {
  return (
    <div className="App">
      <PublicLayout />
    </div>
  );
}

export default App;
