import React from "react";
import GalleryCom from "../../components/GalleryCom/GalleryCom";
import Banner from "../../components/Banner/Banner";
function SingleDenge() {
  const IMAGES = [
    {
      id: 15,
      src: process.env.PUBLIC_URL + "/img/Gallery/denge-8.webp",
      caption: "DENGE",
      size: "200-200",
      class: "one-third",
    },
    {
      id: 16,
      src: process.env.PUBLIC_URL + "/img/Gallery/denge-7.webp",
      caption: "DENGE",
      size: "300-300",
      class: "one-third",
    },
    {
      id: 17,
      src: process.env.PUBLIC_URL + "/img/Gallery/denge-3.webp",
      caption: "DENGE",
      size: "300-300",
      class: "one-third",
    },
    {
      id: 18,
      src: process.env.PUBLIC_URL + "/img/Gallery/denge-4.webp",
      caption: "DENGE",
      size: "200-200",
      class: "one-third",
    },
    {
      id: 19,
      src: process.env.PUBLIC_URL + "/img/Gallery/denge-5.webp",
      caption: "DENGE",
      size: "200-200",
      class: "one-third",
    },
    {
      id: 20,
      src: process.env.PUBLIC_URL + "/img/Gallery/denge-6.webp",
      caption: "DENGE",
      size: "200-200",
      class: "one-third",
    },
    {
      id: 21,
      src: process.env.PUBLIC_URL + "/img/Gallery/denge-2.webp",
      caption: "DENGE",
      size: "200-200",
      class: "two-third",
    },
  ];
  return (
    <div className="col-md-12">
      <Banner title={"DENGE"} />
      <div id="performanz-trainer"></div>
      <div className="container">
        <div className="col-md-12 col-md-offset-2 text-center performanz-heading">
          <h2>Performanz Denge</h2>
        </div>
        <div className="row">
          <div style={{"marginTop": "-30px"}} className="col-lg-8">
            <p>
              Performanz Denge sistemi, insan dengesini etkileyen alt ekstremite
              kas gruplarının analizini yapmak üzere tasarlanmış bir elektronik
              izlem cihazıdır. Temelde kullanılan yöntem denge odağını izleyerek
              kasların kasılma davranışları ortaya çıkarmaktır. Bu bağlamda
              yapılan denge testleri ve izlemler ile sporcunun kas yapısını ve
              denge ölçümlerinin sonuçlarını değerlendiren uzmanlar tarafından
              yapılacak/planlanan antrenmanlara uygulamak sporcuların doğru
              yönde gelişmelerine katkıda bulunacaktır. Performanz Denge tahtası
              yaş grubu 11-19 olan yüzlerce öğrenci üzerinde test edilmiştir.
            </p>
            <p>
              Aynı zamanda Denge Cihazı T.C. Gençlik ve Spor Bakanlığı
              tarafından yürütülen “Türkiye Sportif Yetenek Taraması ve Spora
              Yönlendirme” testlerinde Türkiye genelinde 81 il, 135 noktada 3.
              Ve 4. Sınıfların üzerinde yapılan DENGE TESTLERİ için
              kullanılmaktadır.
            </p>
            <p className="mt-5">Analiz Yapabildiği Kas Grupları:</p>
            <div className="my_container">
              <ul>
                <li>Soleus</li>
                <li>Gastrocnemius</li>
                <li>Flexor digitorum longus</li>
                <li>Flexor hallucis longus</li>
                <li>Extensor digitorum longus</li>
                <li>Plantaris</li>
                <li>Tibialis posterior</li>
                <li>Tibialis anterior</li>
                <li>Peroneus tertius</li>
                <li>Peroneus longus</li>
                <li>Peroneus brevis</li>
              </ul>
            </div>

            <p></p>
            <p>
              Performanz denge ölçüm cihazının yapabildiği ölçüm ve testler ise
              aşağıda sıralanmıştır.
            </p>
            <ol type="a">
              <li>Dinamik ve statik denge</li>
              <li>Çift ayak denge</li>
              <li>Tek ayak denge (sağ/sol)</li>
              <li>Göz açık, göz kapalı denge</li>
              <li>Sağ-Sol ayak arasındaki farklar</li>
              <li>Ön test Son test denge gelişimlerini ölçme</li>
              <li>Plank duruşu ölçümü</li>
              <li>Geriye doğru raporlama</li>
              <li>Yapılan ölçümleri sıralama yapabilme</li>
              <li>Sporcunun kendini geliştirebilmesi için oyunlar.</li>
            </ol>
            <p></p>
            <p>
              Sistem özellikleri ve gereksinimler için{" "}
              <a href="https://performa.nz/docs/Denge_2020.pdf" download="">
                {" "}
                tıklayınız.
              </a>
            </p>
            {/* <p>
              Mobil uygulamamızı indirmek için
              <a href="https://performa.nz/files/apk/Denge.apk" download="">
                {" "}
                tıklayınız.
              </a>
            </p>

            <p>
              Masaüstü uygulamamızı indirmek için
              <a href="https://performa.nz/files/denge-masaustu/Denge.msi" download="">
                {" "}
                tıklayınız.
              </a>
            </p> */}

            {/* <p>
             Uygulamamızı indirmek için
              <a href="https://performa.nz/files/denge-masaustu/app-denge17082022.apk" download="">
                {" "}
                tıklayınız.
              </a>
            </p> */}
          </div>
          <div className="col-lg-4  mb-5 ">
            <img src={process.env.PUBLIC_URL + "/img/Gallery/denge-1.webp"} />
          </div>
        </div>
      </div>
      <div
        id="performanz-started"
        class="performanz-bg"
        style={{
          backgroundImage: `url("${
            process.env.PUBLIC_URL + "/img/bg_img/img_bg_1.jpg"
          }")`,
        }}
      >
        <div class="overlay"></div>
        <div id="performanz-gallery">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-md-offset-2 text-center performanz-heading animate-box fadeInUp animated-fast">
                <h2 style={{ color: "#fff" }}>PERFORMANZ DENGE</h2>
                <p>ÖLÇTÜĞÜNÜ GELİŞTİRİRSİN!</p>
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <div class="row row-bottom-padded-md">
              <div class="col-md-12 image-popup">
                <GalleryCom images={IMAGES} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleDenge;
