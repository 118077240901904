import React from "react";

function Banner({ title }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100px',
        backgroundImage: 'URL("https://wallpapersok.com/images/high/brushed-metal-texture-baiksri9m6rb83dg.jpg")',
        backgroundSize: 'cover',
        }}
    > 
      <h1 style={{margin: '0', textTransform: 'uppercase', color: '#FFF', textShadow: '2px 2px 4px rgba(0,0,0,1)'}}>{title}</h1>
    </div>
  );
}

export default Banner;
