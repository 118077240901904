import React from "react";
import Banner from "../components/Banner/Banner";
import { MdLocationOn } from "react-icons/md";
import { FaPhone } from "react-icons/fa";
import { GrMail } from "react-icons/gr";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

function Contact() {
  const MyMapComponent = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={15}
        defaultCenter={{ lat: 38.452404116859014, lng: 27.18083948174446 }}
      >
        {props.isMarkerShown && (
          <Marker
            position={{ lat: 38.452404116859014, lng: 27.18083948174446 }}
          />
        )}
      </GoogleMap>
    ))
  );

  return (
    <div>
      <Banner title="İletişim" />
      <div id="performanz-contact">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-md-push-1 animate-box mb-5">
              <div className="performanz-contact-info mb-4">
                <h3>Merkez Şubemiz</h3>
                <ul>
                  <li className="address">
                    <div className="mt-1 mb-1">
                      <MdLocationOn style={{ color: "#f85a16" }} size={30} className="me-1"/>
                      Muradiye Mah. C.B.Ü. Teknokent Z-29 <br /> Yunusemre
                      MANİSA
                    </div>
                  </li>
                  <li className="phone">
                    <div className="mt-1 mb-1">
                      <FaPhone style={{ color: "#f85a16" }} size={23} />
                      <a
                        style={{
                          color: "#f85a16",
                          transition: ".5s",
                          textDecoration: "none",
                        }}
                        href="tel://+905069038554"
                      >
                        +90 506-903-85-54
                      </a>
                    </div>
                  </li>
                  <li className="email">
                    <div className="mt-1 mb-1">
                      <GrMail style={{ color: "#f85a16" }} size={23} />
                      <a
                        style={{
                          color: "#f85a16",
                          transition: ".5s",
                          textDecoration: "none",
                          textTransform: "lowercase",
                        }}
                        href="mailto:biz@performa.nz"
                      >
                        biz@performa.nz
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="performanz-contact-info mt-2">
                <h3>Şubemiz</h3>
                <ul>
                  <li className="address">
                    <div className="mt-2 mb-2">
                      <MdLocationOn style={{ color: "#f85a16" }} size={30} className="me-1" />
                      Mansuroğlu Mah. 1593/1 Sk. No:10 Kat:7 Daire:83
                      <br /> Bayraklı İZMİR
                    </div>
                  </li>
                  <li className="phone">
                    <div className="mt-1 mb-1">
                      <FaPhone style={{ color: "#f85a16" }} size={23} />
                      <a
                        style={{
                          color: "#f85a16",
                          transition: ".5s",
                          textDecoration: "none",
                        }}
                        href="tel://+905069038554"
                      >
                        +90 506-903-85-54
                      </a>
                    </div>
                  </li>
                  <li className="email">
                    <div className="mt-1 mb-1">
                      <GrMail style={{ color: "#f85a16" }} size={23} />
                      <a
                        style={{
                          color: "#f85a16",
                          transition: ".5s",
                          textDecoration: "none",
                          textTransform: "lowercase",
                        }}
                        href="mailto:biz@performa.nz"
                      >
                        biz@performa.nz
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-md-push-1 animate-box  mb-5">
              <div
                id="map"
                className="performanz-map"
                style={{ position: "relative", overflow: "hidden" }}
              >
                <MyMapComponent
                  isMarkerShown
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDUTcDr_2_OGXdyjoOmY0cH6mId9GVTfPY&v=3.exp&libraries=geometry,drawing,places"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `400px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
