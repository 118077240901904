import React, { useEffect } from 'react';
import Banner from "../components/Banner/Banner";
import SectionReferance from "../components/SectionReferance/SectionReferance";


function Referances() {
  useEffect(() => {
    // Sayfa yüklendiğinde, sayfa en üst kısmına kaydırılır.
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
        <Banner title="Referanslar" />
        <SectionReferance />
    </div>
  );
}

export default Referances;
