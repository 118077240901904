import React from 'react'
import Product from '../Product/Product';
import ProductsList from './ProductsList.json'

function SectionProduct() {
    return (
        <div id="performanz-services" className="performanz-bg-section">
            <div className="container">
                <div className="row">
                    {
                        ProductsList.map((product) => {
                            return (
                                <div key={product.id} className="col-lg-4 mb-5 text-center animate-box fadeInUp animated-fast">
                                    <Product product={product} />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default SectionProduct;
