import React from "react";
import { BsFacebook, BsInstagram, BsLinkedin, BsYoutube } from "react-icons/bs";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer
      id="performanz-footer"
      className="performanz-bg"
      style={{
        // backgroundImage: "url('https://performa.nz/images/img-bg-1.webp')",
        backgroundColor: "black",
      }}
      role="contentinfo"
    >
      <div className="container">
        <div className="row row-pb-md">
          <div className="col-md-4 performanz-widget">
            <h3>Performanz VAP</h3>
            <p>Veri analiz portalı demo girişini incelemek için tıklayın.</p>
            <p>
              <a class="btn btn-primary" href="https://panel.performa.nz">
                Vap Demo
              </a>
            </p>
          </div>
          <div className="col-md-4 performanz-widget">
            <h3>Ürünlerimiz</h3>
            <Link
              className="footer-link"
              style={{
                color: "#f85a16!important",
                transition: ".5s",
                textDecoration: "none",
              }}
              to="/product/hitit-assistant"
            >
              HİTİT ASSISTANT
            </Link>
            <br />
            <Link
              className="footer-link"
              style={{
                color: "#f85a16!important",
                transition: ".5s",
                textDecoration: "none",
              }}
              to="/product/hitit-move"
            >
              HİTİT MOVE
            </Link>
            <br />
            <Link
              className="footer-link"
              style={{
                color: "#f85a16!important",
                transition: ".5s",
                textDecoration: "none",
              }}
              to="/product/spark"
            >
              SPARK
            </Link>
            <br />
            <Link
              className="footer-link"
              style={{
                color: "#f85a16",
                transition: ".5s",
                textDecoration: "none",
              }}
              to="/product/denge"
            >
              DENGE
            </Link>
            <br />
            <Link
              className="footer-link"
              style={{
                color: "#f85a16",
                transition: ".5s",
                textDecoration: "none",
              }}
              to="/product/veri-analiz-portali"
            >
              VERİ ANALİZ PORTALI
            </Link>
            <br />
          </div>
          <div className="col-md-4 performanz-widget">
            <h3>İletişim</h3>
            <p>Muradiye Mah. C.B.Ü. Teknokent Z-29 Yunusemre Manisa</p>
            <a href="tel:+905069038554"  style={{
                color: "#f85a16",
                transition: ".5s",
                textDecoration: "none",
              }}>+90 506-903-85-54</a>
            <br />
            <a
              style={{
                color: "#f85a16",
                transition: ".5s",
                textDecoration: "none",
              }}
              href="mailto:biz@performa.nz"
            >
              biz@performa.nz
            </a>
          </div>
        </div>
        <div className="row copyright">
          <div className="col-md-12 text-center">
            <p>
              <small className="block">© 2021 All Rights Reserved.</small>
              <small className="block">
                Designed by{" "}
                <Link
                  className="footer-link"
                  target="_blank"
                  to="/"
                >
                  performa.nz
                </Link>
              </small>
            </p>
            <p></p>
            <ul className="performanz-social-icons">
              <li className="social-media-list">
                <a href="https://www.facebook.com/PerformanzArge">
                  <BsFacebook size={36} />
                </a>
              </li>
              <li className="social-media-list">
                <a href="https://www.youtube.com/c/Performanz">
                  <BsYoutube size={36} />
                </a>
              </li>
              <li className="social-media-list">
                <a href="https://www.instagram.com/performa.nz">
                  <BsInstagram size={36} />
                </a>
              </li>
              <li className="social-media-list">
                <a href="https://tr.linkedin.com/company/performanz-arge">
                  <BsLinkedin  size={36} />
                </a>
              </li>
            </ul>
            <p></p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
