import React from "react";
import { BsFacebook, BsYoutube, BsInstagram, BsLinkedin } from "react-icons/bs";
// import './Header.css'

function Header() {

    const socialMedia = [
        {
            link: "https://www.facebook.com/PerformanzArge",


        },
        {},
        {},
        {},
    ]



  return (
    <div className="top">
      <div className="container">
        <div className="col-xs-12 text-right">
          <p className="num">Tel: +90 (506) 903 85 54</p>
          <ul className="performanz-social">
            <li className="social-media-list">
              <a href="https://www.facebook.com/PerformanzArge">
                <BsFacebook style={{color: 'white'}} size={18}/>
              </a>
            </li>
            <li className="social-media-list">
              <a href="https://www.youtube.com/c/Performanz">
                <BsYoutube  style={{color: 'white'}} size={18}/>
              </a>
            </li>
            <li className="social-media-list">
              <a href="https://www.instagram.com/performa.nz">
                <BsInstagram  style={{color: 'white'}} size={18}/>
              </a>
            </li>
            <li className="social-media-list">
              <a href="https://tr.linkedin.com/company/performanz-arge">
                <BsLinkedin  style={{color: 'white'}} size={18}/>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
