import React from "react";
import Header from "../components/Header/Header";
import Navbar from "../components/Navbar/Navbar";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Contact from "../pages/Contact";
import Gallery from "../pages/Gallery";
import Home from "../pages/Home";
import About from "../pages/About";
import Products from "../pages/Products";
import SingleProduct from "../pages/SingleProduct";
import Footer from "../components/Footer/Footer";
import AppLinks from "../pages/AppLinks";
import SingleApp from "../pages/SingleApp";
import Referances from "../pages/Referances";

function PublicLayout() {
  return (
    <div className="performanz-nav" role="navigation">
      <Header />

      <Router>
        <Navbar />

        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route exact path="/product/:title">
            <SingleProduct />
          </Route>
          <Route exact path="/products">
            <Products />
          </Route>
          <Route path="/referances">
            <Referances />
          </Route>
          <Route path="/gallery">
            <Gallery />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/appfiles">
            <AppLinks />
          </Route>
          <Route exact path="/files/:title">
            <SingleApp />
          </Route>



          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default PublicLayout;
