import React, { useState } from 'react';
import ModalVideo from "react-modal-video";
import { Link } from 'react-router-dom';
import VideosJson from './Videos.json'

function Videos() {
    const [isOpen, setOpen] = useState(false)
    const [isUrl, setUrl] = useState("")

    return (
        <div id="performanz-trainer">

            <div className="container">
                <div className="row animate-box fadeInUp animated-fast">
                    <div className="col-md-12 col-md-offset-2 text-center performanz-heading">
                        <h2>Tanıtım Videoları</h2>
                    </div>
                </div>
                <React.Fragment classNames="modalVideo">
                    <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={isUrl} onClose={() => setOpen(false)} />
                </React.Fragment>
                <div className="row">
                    {
                        VideosJson.map((video) => {
                            return (
                                <div key={video.id} className="col-md-4 col-sm-4 animate-box fadeInUp animated-fast">
                                    <div className="trainer">
                                        <a onClick={() => {setOpen(true); setUrl(video.id)}}>
                                            <img className="img-responsive" src={`${process.env.PUBLIC_URL}/img/video_banner/${video.img}`} alt="trainer" /></a>
                                        <div className="title">
                                            <h3><Link to={'/product/spark'}>{video.title}</Link></h3>
                                            <span>Performanz</span>
                                        </div>

                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    )
}

export default Videos
