import React from 'react'
import Banner from '../components/Banner/Banner'
import GalleryCom from '../components/GalleryCom/GalleryCom'

function Gallery() {
    const IMAGES =
        [{
            id: 1,
            src: process.env.PUBLIC_URL + "/img/Gallery/move-2.webp",
            caption: "HİTİT ASSİSTAN MOVE",
            size: '300-300',
            class: 'one-third'
        },
        {
            id: 2,
            src: process.env.PUBLIC_URL + "/img/Gallery/move-3.webp",
            caption: "HİTİT ASSİSTAN MOVE",
            size: '300-300',
            class: 'one-third'
        },
        {
            id: 3,
            src: process.env.PUBLIC_URL + "/img/Gallery/move-4.webp",
            caption: "HİTİT ASSİSTAN MOVE",
            size: '200-200',
            class: 'one-third'
        },
        {
            id: 4,
            src: process.env.PUBLIC_URL + "/img/Gallery/move-6.webp",
            caption: "HİTİT ASSİSTAN MOVE",
            size: '200-200',
            class: 'one-third'
        },
        {
            id: 5,
            src: process.env.PUBLIC_URL + "/img/Gallery/move-7.webp",
            caption: "HİTİT ASSİSTAN MOVE",
            size: '200-200',
            class: 'one-third'
        },
        {
            id: 6,
            src: process.env.PUBLIC_URL + "/img/Gallery/move-5.webp",
            caption: "HİTİT ASSİSTAN MOVE",
            size: '200-200',
            class: 'two-third'
        },
        {
            id: 7,
            src: process.env.PUBLIC_URL + "/img/Gallery/move-6.webp",
            caption: "HİTİT ASSİSTAN MOVE",
            size: '200-200',
            class: 'one-third'
        },
        {
            id: 8,
            src: process.env.PUBLIC_URL + "/img/Gallery/hitit-1.webp",
            caption: "HİTİT ASSİSTAN",
            size: '300-300',
            class: 'one-third'
        },
        {
            id: 9,
            src: process.env.PUBLIC_URL + "/img/Gallery/hitit-2.webp",
            caption: "HİTİT ASSİSTAN",
            size: '300-300',
            class: 'one-third'
        },
        {
            id: 10,
            src: process.env.PUBLIC_URL + "/img/Gallery/hitit-3.webp",
            caption: "HİTİT ASSİSTAN",
            size: '200-200',
            class: 'one-third'
        },
        {
            id: 11,
            src: process.env.PUBLIC_URL + "/img/Gallery/hitit.webp",
            caption: "HİTİT ASSİSTAN",
            size: '200-200',
            class: 'one-third'
        },
        {
            id: 12,
            src: process.env.PUBLIC_URL + "/img/Gallery/hitit-4.webp",
            caption: "HİTİT ASSİSTAN",
            size: '200-200',
            class: 'one-third'
        },
        {
            id: 13,
            src: process.env.PUBLIC_URL + "/img/Gallery/hitit-5.webp",
            caption: "HİTİT ASSİSTAN",
            size: '200-200',
            class: 'two-third'
        },
        {
            id: 14,
            src: process.env.PUBLIC_URL + "/img/Gallery/hitit-7.webp",
            caption: "HİTİT ASSİSTAN",
            size: '200-200',
            class: 'one-third'
        },
        {
            id: 15,
            src: process.env.PUBLIC_URL + "/img/Gallery/denge-8.webp",
            caption: "DENGE",
            size: '200-200',
            class: 'one-third'
        },
        {
            id: 16,
            src: process.env.PUBLIC_URL + "/img/Gallery/denge-7.webp",
            caption: "DENGE",
            size: '300-300',
            class: 'one-third'
        },
        {
            id: 17,
            src: process.env.PUBLIC_URL + "/img/Gallery/denge-3.webp",
            caption: "DENGE",
            size: '300-300',
            class: 'one-third'
        },
        {
            id: 18,
            src: process.env.PUBLIC_URL + "/img/Gallery/denge-4.webp",
            caption: "DENGE",
            size: '200-200',
            class: 'one-third'
        },
        {
            id: 19,
            src: process.env.PUBLIC_URL + "/img/Gallery/denge-5.webp",
            caption: "DENGE",
            size: '200-200',
            class: 'one-third'
        },
        {
            id: 20,
            src: process.env.PUBLIC_URL + "/img/Gallery/denge-6.webp",
            caption: "DENGE",
            size: '200-200',
            class: 'two-third'
        },
        {
            id: 21,
            src: process.env.PUBLIC_URL + "/img/Gallery/denge-2.webp",
            caption: "DENGE",
            size: '200-200',
            class: 'one-third'
        },
        {
            id: 22,
            src: process.env.PUBLIC_URL + "/img/Gallery/spark-2.webp",
            caption: "SPARK",
            size: '200-200',
            class: 'one-third'
        },
        {
            id: 23,
            src: process.env.PUBLIC_URL + "/img/Gallery/spark-3.webp",
            caption: "SPARK",
            size: '200-200',
            class: 'one-third'
        },
        {
            id: 24,
            src: process.env.PUBLIC_URL + "/img/Gallery/spark-4.webp",
            caption: "SPARK",
            size: '300-300',
            class: 'one-third'
        },
        {
            id: 25,
            src: process.env.PUBLIC_URL + "/img/Gallery/spark-5.webp",
            caption: "SPARK",
            size: '300-300',
            class: 'one-third'
        },
        {
            id: 26,
            src: process.env.PUBLIC_URL + "/img/Gallery/spark-6.webp",
            caption: "SPARK",
            size: '200-200',
            class: 'one-third'
        },
        {
            id: 27,
            src: process.env.PUBLIC_URL + "/img/Gallery/spark-7.webp",
            caption: "SPARK",
            size: '200-200',
            class: 'two-third'
        },
        {
            id: 28,
            src: process.env.PUBLIC_URL + "/img/Gallery/spark-8.webp",
            caption: "SPARK",
            size: '200-200',
            class: 'one-third'
        }

        ]
    return (
        <>

            <Banner
                title="GALERİ" />
            <div className="container" style={{marginTop: '350px', marginBottom: '-150px'}}>
                <div className="row">
                    <div className="col-md-12 col-md-offset-2 text-center performanz-heading animate-box fadeInUp animated-fast" style={{marginTop: '50px'}}>
                        <h2>Fotoğraflar</h2>
                    </div>
                </div>
            </div>
            <GalleryCom images={IMAGES} />
        </>
    )
}

export default Gallery
