import React from "react";
import AboutContentJson from "./AboutContent.json";
import Banner from "../components/Banner/Banner";
import Footer from "../components/Footer/Footer";

function About() {
  return (
    <div>
      <Banner title="Hakkımızda" />
      <div id="performanz-trainer">
        <div class="container">
          {AboutContentJson.map((content) => {
            return (
              <div
                key={content.id}
                class="row animate-box fadeInUp animated-fast"
              >
                <div class="col-md-12 col-md-offset-2 performanz-heading">
                  <h2 class="text-center">{content.title}</h2>
                  <p>{content.content}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default About;
