import React from "react";
import { version } from "react-dom";
import Banner from "../components/Banner/Banner";
import AppLinksJson from "./AppLinks.json";
import SingleApp from "./SingleApp";

function AppLinks() {
  return (
    <div>
      <Banner title={"Uygulama bağlantıları"} />
      {AppLinksJson.map((content) => {
        return (
          <div
            key={content.id}
            className="container mt-5 mb-5 animate-box fadeInUp animated-fast"
          >
            <SingleApp
              title={content.title}
              link={content.link}
              desc={content.desc}
              image={content.image}
              last_updated={content.last_updated}
              version={content.version}
            />
          </div>

          // <div key={content.id} class="row animate-box fadeInUp animated-fast">
          //   <div class="col-md-12 col-md-offset-2 performanz-heading">
          //     <h2 class="text-center">{content.title}</h2>
          //     <a href={content.link}>{content.link}</a>
          //   </div>
          //   <SingleApp title={content.title}/>
          // </div>
        );
      })}
    </div>
  );
}

export default AppLinks;
