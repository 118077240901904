import React from "react";
import GalleryCom from "../../components/GalleryCom/GalleryCom";
import Banner from "../../components/Banner/Banner";

function SingleZipzip() {
  const IMAGES = [
    {
      id: 23,
      src: process.env.PUBLIC_URL + "/img/Gallery/zipzip-1.png",
      caption: "ZIPZIP",
      size: "200-200",
      class: "two-third",
    },
    {
      id: 24,
      src: process.env.PUBLIC_URL + "/img/Gallery/zipzip-2.png",
      caption: "ZIPZIP",
      size: "300-300",
      class: "one-third",
    },
    {
      id: 25,
      src: process.env.PUBLIC_URL + "/img/Gallery/zipzip-3.png",
      caption: "ZIPZIP",
      size: "300-300",
      class: "one-third",
    },
  ];
  return (
    <div>
      <Banner title={"ZIPZIP"} />
      <div id="performanz-trainer">
        <div class="container">
          <div class="row animate-box fadeInUp animated-fast">
            <div class="col-md-12 col-md-offset-2 text-center performanz-heading">
              <h2>Performanz Zıpzıp</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div
                  style={{ marginTop: "-30px" }}
                  class="col-lg-8  animate-box fadeInUp animated-fast"
                >
                  <h3> Dikey Sıçrama Ölçümü </h3>
                  Performanz dikey sıçrama testi cihazı (ZIPZIP) bir verici ve
                  bir alıcı çubuktan oluşan optik bir ölçüm sistemidir. Sıçrama
                  dayanıklılığı ve yorgunluk indeksi gibi veriler yardımıyla,
                  sporcunun o anki durumu hakkında fikir sahibi olunarak
                  eksiklerin belirlenmesine yardımcı olur.
                  <br /> <br />
                  <p>
                    Dijital sensörlü zemin kullanılarak yapılan 'Dikey Sıçrama
                    Testi' ile geleneksel hesaplama yöntemi olan havada kalış
                    süresi ile sıçrama yüksekliği ölçülür. Böylece patlayıcı
                    kuvvet ve anaerobik kapasite de belirlenir.
                  </p>
                  <br />
                  <p class="mt-3">Hangi testler yapılabilir:</p>
                  <div className="my_container">
                    <ul>
                      <li>Sürekli aynı noktaya yapılan sıçrama testleri</li>
                      <li>Kasadan düşüş atlaması ve derinlik sıçramaları</li>
                      <li>Tekli veya çoklu reaksiyon sıçramaları</li>
                      <li>Ayak hızı ve çabukluğu</li>
                      <li>Reaksiyon zamanı</li>
                      <li>Sağlık topu ile güç egzersizleri ve daha fazlası</li>
                    </ul>
                  </div>
                  <br />
                  <p className="mb-5">
                    {" "}
                    Sistem özellikleri ve gereksinimler için{" "}
                    <a
                      href="https://performa.nz/docs/Zipzip_2021.pdf"
                      download=""
                    >
                      {" "}
                      tıklayınız.
                    </a>
                  </p>
                  {/* <p>
                    Uygulamamızı indirmek için
                    <a
                      href="https://performa.nz/files/apk/performanz_zipzip.apk"
                      download=""
                    >
                      {" "}
                      tıklayınız.
                    </a>
                  </p> */}

                  {/* <p>
                    Uygulamamızı indirmek için
                    <a
                      href="https://performa.nz/files/apk/ZıpzıpGuncel.apk"
                      download=""
                    >
                      {" "}
                      tıklayınız.
                    </a>
                  </p> */}
                </div>
                <div class="col-lg-4 d-flex justify-content-center mb-5 animate-box fadeInUp animated-fast">
                  <img
                    src={process.env.PUBLIC_URL + "/img/Gallery/zipzip-1.png"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="performanz-started"
          class="performanz-bg"
          style={{
            backgroundImage: `url("${
              process.env.PUBLIC_URL + "/img/bg_img/img_bg_1.jpg"
            }")`,
          }}
        >
          <div class="overlay"></div>
          <div id="performanz-gallery">
            <div class="container">
              <div class="row">
                <div class="col-md-12 col-md-offset-2 text-center performanz-heading animate-box fadeInUp animated-fast">
                  <h2 style={{ color: "#fff" }}>PERFORMANZ ZIPZIP</h2>
                  <p>ÖLÇTÜĞÜNÜ GELİŞTİRİRSİN!</p>
                </div>
              </div>
            </div>

            <div class="container-fluid">
              <div class="row row-bottom-padded-md">
                <div class="col-md-12 image-popup">
                  <GalleryCom images={IMAGES} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleZipzip;
