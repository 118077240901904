import React from "react";
import GalleryCom from "../components/GalleryCom/GalleryCom";
import MainSlider from "../components/MainSlider/MainSlider";
import Referances from "../components/Referance/Referance";
import SectionProduct from "../components/SectionProduct/SectionProduct";
import Videos from "../components/Videos/Videos";
import PublicLayout from "../layouts/PublicLayout";

function Home() {
  const IMAGES =
  [{
      id: 1,
      src: process.env.PUBLIC_URL + "/img/Gallery/spark-3.webp",
      caption: "Spark",
      size: '300-300',
      class:'one-third'
  },
  {
      id: 2,
      src: process.env.PUBLIC_URL + "/img/Gallery/denge-6.webp",
      caption: "Denge",
      size: '300-300',
      class:'one-third'
  },
  {
      id: 3,
      src: process.env.PUBLIC_URL + "/img/Gallery/move-7.webp",
      caption: "Move",
      size: '200-200',
      class:'one-third'
  },
  {
      id: 4,
      src: process.env.PUBLIC_URL + "/img/Gallery/spark-5.webp",
      caption: "Spark",
      size: '200-200',
      class:'one-third'
  },
  {
      id: 5,
      src: process.env.PUBLIC_URL + "/img/Gallery/denge-7.webp",
      caption: "Spark",
      size: '200-200',
      class:'one-third'
  },
  {
      id: 6,
      src: process.env.PUBLIC_URL + "/img/Gallery/HITIT-5.webp",
      caption: "Spark",
      size: '200-200',
      class:'two-third'
  },
  {
      id: 7,
      src: process.env.PUBLIC_URL + "/img/Gallery/HITIT-4.webp",
      caption: "Spark",
      size: '200-200',
      class:'one-third'
  }

  ]
  return (
    <div>
      <MainSlider />
      <SectionProduct />
      <Videos />
      <Referances />
      <GalleryCom  images={IMAGES}/>
    </div>
  );
}
 
export default Home;
