import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReferanceLogos from '../SectionReferance/ReferanceList.json';
import { Link } from "react-router-dom/cjs/react-router-dom";

const categories = [
    { key: "all", label: "Tümü" },
    { key: "kamuKuruluslari", label: "Kamu" },
    { key: "sporKulüpleri", label: "Spor Kulüpleri" },
    { key: "universiteler", label: "Üniversiteler" },
    { key: "liseler", label: "Liseler" },
    { key: "ozel", label: "Özel" }
];

function SamplePrevArrow(props) {
    return <div />;
}

const Referance = () => {
    const [selectedCategory, setSelectedCategory] = useState("all");
    const [filteredLogos, setFilteredLogos] = useState(Object.values(ReferanceLogos).flat());

    // Responsive settings
    const settings = {
        cssEase: 'linear',
        dots: false,
        infinite: false,
        speed: 1200,
        autoplaySpeed: 1,
        autoplay: true,
        slidesToShow: 6, // Default value for larger screens
        adaptiveHeight: false,
        slidesToScroll: 1,
        nextArrow: <SamplePrevArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6, // 1200px ve altı
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5, // 992px ve altı
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4, // 768px ve altı
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3, // 576px ve altı
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2, // 480px ve altı
                }
            }
        ]
    };

    useEffect(() => {
        // Kategori değiştiğinde filteredLogos'u sıfırlıyoruz ve yeniden ayarlıyoruz.
        if (selectedCategory === "all") {
            setFilteredLogos(Object.values(ReferanceLogos).flat());
        } else {
            setFilteredLogos(ReferanceLogos[selectedCategory] || []);
        }
    }, [selectedCategory]); // selectedCategory değiştiğinde çalışacak

    useEffect(() => {console.log(filteredLogos)}, [filteredLogos])

    return (
        <div id="performanz-schedule" className="performanz-bg" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/bg_img/img_bg_1.jpg)`, padding: "40px 0px" }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-md-offset-2 text-center performanz-heading animate-box fadeInUp animated-fast" style={{ margin: 0 }}>
                        <h2>Referanslar</h2>
                    </div>
                    <div className="category-buttons" style={{ textAlign: "center", marginBottom: "20px" }}>
                        {categories.map((category) => (
                            <button
                                key={category.key}
                                onClick={() => setSelectedCategory(category.key)}
                                style={{
                                    width: "160px",
                                    margin: "5px",
                                    padding: "10px 20px",
                                    border: "none",
                                    cursor: "pointer",
                                    backgroundColor: selectedCategory === category.key ? "#f85a16" : "#ccc",
                                    color: "white",
                                    borderRadius: "5px",
                                    fontWeight: "bold"
                                }}
                            >
                                {category.label}
                            </button>
                        ))}
                    </div>
                </div>
                <div style={{ backgroundColor: 'white', height: '250px', overflow: 'hidden', borderRadius: '8px' }}>
                    <Link to={'/referances'}>
                        {/* Slider'a key ekledik, böylece her kategori değişiminde sıfırlanacak */}
                        <Slider key={selectedCategory} {...settings}>
                            {filteredLogos.map((logo) => (
                                <img key={logo.id} src={`${process.env.PUBLIC_URL}/img/referances/${logo.image}`} alt={logo.name} />
                            ))}
                        </Slider>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Referance;
