import React from "react";
import { useParams } from "react-router";
import Banner from "../components/Banner/Banner";
import SingleDenge from "./singleProductPages/SingleDenge";
import SingleHitit from "./singleProductPages/SingleHitit";
import SingleHititMove from "./singleProductPages/SingleHititMove";
import SingleSpark from "./singleProductPages/SingleSpark";
import SingleVeriAnalizPortali from "./singleProductPages/SingleVeriAnalizPortali";
import SingleZipzip from "./singleProductPages/SingleZipzip";
import SingleBoyTarti from "./singleProductPages/SingleBoyTarti";

function SingleProduct() {
  let { title } = useParams();

  let xdxd = title.charAt(0).toUpperCase() + title.slice(1).replaceAll("-", " ");
  let words = xdxd.split(" ");

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  let myTitle = words.join(" ");

  console.log(title);
  console.log(xdxd);
  console.log(myTitle);
  return (
    <div>
      {/* <Banner title={title} /> */}
      {/* <h1>Single Product Page</h1>
      <h1>{title}</h1> */}

      {(() => {
        switch (title) {
          case "spark":
            return <SingleSpark />;
          case "denge":
            return <SingleDenge />;
          case "hitit-assistant":
            return <SingleHitit />;
          case "hitit-assistant-move":
            return <SingleHititMove />;
          case "boy-tarti":
            return <SingleBoyTarti />;
          case "zipzip":
            return <SingleZipzip />;
          case "veri-analiz-portali":
            return <SingleVeriAnalizPortali />;
          default:
            return " ";
        }
      })()}
    </div>
  );
}

export default SingleProduct;
